// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px; // Small tablets (portrait view)
$screen-md-min: 768px; // Tablets and small desktops
$screen-lg-min: 992px; // Large tablets and desktops
$screen-xl-min: 1200px; // Small devices
$margin: 1.5rem;
$titles_margin: 1rem;
$transition-speed: 600ms;
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}
// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}
// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}
// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

body {
  overflow-x: hidden;
  color: #222;
  background: rgb(255,255,255);
  font-family: 'sui', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 1.375rem;
  touch-action: manipulation;
}

.link,
a,
a:focus,
a:visited {
  color: #7d7777;
  text-decoration: none;
  cursor: pointer;
}

.link:hover,
a:hover {
  color: #424040;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: $titles_margin;
  font-size: 1.175rem;
  font-weight: normal;
  font-style: normal;
  @include sm {
    font-size: 1.375rem;
  }
}

.white {
  color: white;

  .link,
  a {
    color: rgb(179, 179, 179);
  }

  .link:hover,
  a:hover {
    color: white;
  }
}

.slider {
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
  height: 100%;
  position: fixed;
}

.slide {
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
}

.loader {
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;

  .spinner {
    width: 50%;
    height: 50%;
    opacity: 1;
    -webkit-animation: pulse 500ms ease-in-out infinite alternate;
    -moz-animation: pulse 500ms ease-in-out infinite alternate;
    animation: pulse 500ms ease-in-out infinite alternate;
  }
}

.grid {
  .loader {
    display: none;
    //position: relative;
  }
}
@-webkit-keyframes pulse {
  0% {
    background-color: #E3E3E3;
  }

  100% {
    background-color: #f4f4f4;
  }
}
@-moz-keyframes pulse {
  0% {
    background-color: #E3E3E3;
  }

  100% {
    background-color: #f4f4f4;
  }
}
@keyframes pulse {
  0% {
    background-color: #E3E3E3;
  }

  100% {
    background-color: #f4f4f4;
  }
}

.slide-item {
  //transform: translateZ(0);
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;

  .slide-visual {
    max-width: calc(100% - #{$margin*2});
    max-height: calc(100% - 10rem);

    video {
      max-height: 100%;
    }
  }
}

.slide-enter-active {
  transition: all $transition-speed ease-in-out;
}

.slide-exit-active {
  transition: all $transition-speed ease-in-out;
}

.fade-transition {
  .slide-enter {
    opacity: 0;
  }

  .slide-enter-active {
    opacity: 1;
  }

  .slide-exit {
    opacity: 1;
  }

  .slide-exit-active {
    opacity: 0;
  }
}

.right-transition {
  .slide-enter {
    transform: translateX(100%);
  }

  .slide-enter-active {
    transform: translateX(0%);
  }

  .slide-exit {
    transform: translateX(0%);
  }

  .slide-exit-active {
    transform: translateX(-100%);
  }
}

.left-transition {
  .slide-enter {
    transform: translateX(-100%);
  }

  .slide-enter-active {
    transform: translateX(0%);
  }

  .slide-exit {
    transform: translateX(0%);
  }

  .slide-exit-active {
    transform: translateX(100%);
  }
}

.fixed {
  position: fixed;
  z-index: 100;
}

.bottom-left {
  bottom: calc(#{$margin - $titles_margin});
  left: calc(#{$margin - $titles_margin});
  right: calc(#{$margin - $titles_margin});
}

.bottom-right {
  bottom: calc(#{$margin - $titles_margin});
  right: calc(#{$margin - $titles_margin});
}

.top-left {
  top: calc(#{$margin - $titles_margin});
  left: calc(#{$margin - $titles_margin});
}

.top-right {
  top: calc(#{$margin - $titles_margin});
  right: calc(#{$margin - $titles_margin});
}

.label {
  background: rgba(255,255,255,0.9);
}

.grid {
  display: -webkit-box;
  /* Not needed if autoprefixing */
  display: -ms-flexbox;
  /* Not needed if autoprefixing */
  display: flex;
  margin-left: -$margin/2;
  /* gutter size offset */
  width: auto;
}

.gallery {
  width: 100%;
  min-height: 100%;
  position: absolute;
  background: rgba(0,0,0,1);
  position: absolute;
  z-index: 10;
}

.gallery-content {
  padding: 2rem $margin;
}

.grid_column {
  padding-left: $margin/2;
  /* gutter size */
  background-clip: padding-box;
}
/* Style your items */
.grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: $margin/2;

  img {
    width: 100%;
  }
}

.grid-item {
  .slide-visual {
    cursor: pointer;
  }

  img {
    vertical-align: middle;
  }
}

.slide-visual {
  opacity: 0;
  transition: all 500ms ease-in-out;

  &.loaded {
    opacity: 1;
  }
}

.column {
  float: left;
}

.column-small {
  width: 80%;
  @include md {
    width: 33.3%;
  }
}

.column-large {
  width: 100%;
  @include md {
    width: 50%;
  }
}

.row {
  margin: calc(#{$margin - $titles_margin});
}
/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}